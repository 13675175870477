<template>
  <div class="container" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.4)">
    <div class="content">
      <div class="item item_1">
        <div
          class="map-container"
          style="width: 100%; height: 100%"
          ref="myEchart"
        ></div>
        <div class="mapTips">
          <p>绿灯分值 <span> >80分 </span></p>
          <p>黄灯分值 <span> 60-80分 </span></p>
          <p>红灯分值 <span> <60分 </span></p>
        </div>
      </div>
      <div class="item item_2">
        <div class="title">核心指标</div>
        <div class="detail">
          <div
            class="itemDetail"
            v-for="(item, index) in entryData"
            :key="index"
          >
            <div class="label" :class="['icon_' + (index + 1)]">
              {{ item.label }}
            </div>
            <div class="desc">
            {{ item.value }}
            </div>
          </div>
        </div>
      </div>
      <div class="item item_3">
        <div class="subitem">
          <div class="subtitle">经销商排名</div>
          <div class="subcontent">
            <table class="table" cellspacing="0" cellspadding="0">
              <tr>
                <th></th>
                <th>排行</th>
                <th>成交</th>
              </tr>
              <tr v-for="item in companyData" :key="item.id">
                <td>{{ item.name }}</td>
                <td>{{ item.rank }}</td>
                <td :style="'color:'+getColor(item.value)">{{ item.value }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="subitem">
          <div class="subtitle">销售员排名</div>
          <div class="subcontent">
            <table class="table" cellspacing="0" cellspadding="0">
              <tr>
                <th></th>
                <th>排行</th>
                <th>成交</th>
              </tr>
              <tr v-for="item in sellerData" :key="item.id">
                <td>{{ item.name }}</td>
                <td>{{ item.rank }}</td>
                <td :style="'color:'+getColor(item.value)">{{ item.value }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="item item_4">
        <table class="table" cellspacing="0" cellspadding="0">
          <tr>
            <th></th>
            <th>排行</th>
            <th>战斗力<br />指数</th>
            <th>战力上岗<br />得分</th>
            <th>跟进完成<br />得分</th>
            <th>成交逾期<br />扣分</th>
            <th>跟进质量<br />爆灯得分</th>
            <th>成交<br />得分</th>
            <th>战败<br />得分</th>
          </tr>
          <tr v-for="item in regionData" :key="item.id">
            <th @click="jumpToRegion(item)">{{ item.name }}</th>
            <td>{{ item.rank }}</td>
              <td :style="'color:'+getColor(item.value1)">{{ item.value1 }}</td>
            <td :style="'color:'+getColor(item.value2)">{{ item.value2 }}</td>
            <td :style="'color:'+getColor(item.value3)">{{ item.value3 }}</td>
            <td >{{ item.value4 }}</td>
            <td :style="'color:'+getColor(item.value5)">{{ item.value5 }}</td>
            <td :style="'color:'+getColor(item.value6)">{{ item.value6 }}</td>
            <td>{{ item.value7 }}</td>
          </tr>
        </table>
      </div>
      <div class="item item_4">
        <table class="table" cellspacing="0" cellspadding="0">
          <tr>
            <th></th>
            <th>排行</th>
            <th>战斗力<br />指数</th>
            <th>战力上岗<br />得分</th>
            <th>跟进完成<br />得分</th>
            <th>成交逾期<br />扣分</th>
            <th>跟进质量<br />爆灯得分</th>
            <th>成交<br />得分</th>
            <th>战败<br />得分</th>
          </tr>
          <tr v-for="item in areaData" :key="item.id">
            <th  @click="jumpToArea(item)">{{ item.name }}</th>
            <td>{{ item.rank }}</td>
            <td :style="'color:'+getColor(item.value1)">{{ item.value1 }}</td>
            <td :style="'color:'+getColor(item.value2)">{{ item.value2 }}</td>
            <td :style="'color:'+getColor(item.value3)">{{ item.value3 }}</td>
            <td >{{ item.value4 }}</td>
            <td :style="'color:'+getColor(item.value5)">{{ item.value5 }}</td>
            <td :style="'color:'+getColor(item.value6)">{{ item.value6 }}</td>
            <td>{{ item.value7 }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { postData } from "@/api/index";
import echarts from "echarts";
import "../../../node_modules/echarts/map/js/china.js";
import nameMap from "@/utils/nameMap.js";
import {jump} from "@/utils/index"
export default {
  data() {
    return {
      loading: false,
      entryData: [],
      companyData: [],
      sellerData: [],
      regionData: [],
      areaData: [],
      info: [],
    };
  },
  created() {},
  mounted() {
    this.getDataBySearch();
  },
  methods: {
    async getDataBySearch() {
      this.loading = true;
      // const query={is_mobile:1,...this.$store.state.query}
      const query={...this.$store.state.query}
      const { data = {} } = await postData(
        "/api/board_battle/country",
        query
      );  
   
      
      // console.log(data);
    
      const { area, region, province, core, company, seller } = data;

      this.entryData = [
        { label: "战斗力指数", value: core.month.core1 },
        { label: "成交率", value: core.month.core2},
        { label: "战败率", value: core.month.core3},
        { label: "爆灯率", value: core.month.core4 },
        { label: "逾期率", value: core.month.core5 },
      ];
      this.companyData = company.slice(0, 8);
      this.sellerData = seller.slice(0, 8);
      this.regionData = region;
      this.areaData = area;
      this.province = province.map((o) => ({
        name: o.name,
        value: [o.lng,o.lat],
        num:o.value,
        itemStyle:{color:this.getColor(o.value)}
      }));
      this.initEcharts(this.province);
      this.loading = false;
    },
    initEcharts(dataValue) {
      this.myEchart = echarts.init(this.$refs.myEchart);
  
      let option = {
        tooltip: {
          show: false,
        },
        geo: {
          map: "china",
          roam: false, // 一定要关闭拖拽
          zoom: 1.23,
          center: [105, 36], // 调整地图位置
          label: {
            normal: {
              show: false, //关闭省份名展示
              fontSize: "10",
              color: "#fff",
            },
            emphasis: {
              show: true,
            },
          },
          itemStyle: {
            normal: {
              areaColor: "#0462fd",
              borderColor: "#0462fd",
              borderWidth: 1, //设置外层边框
              shadowBlur: 5,
              shadowOffsetY: 3,
              shadowOffsetX: 0,
              shadowColor: "#0462fd",
            },
          },
        },
        series: [
          {
            type: "map",
            map: "china",
            roam: false,
            zoom: 1.23,
            center: [105, 36],
            // geoIndex: 1,
            // aspectScale: 0.75, //长宽比
            showLegendSymbol: false, // 存在legend时显示
            label: {
              normal: {
                show: true,
                color: "#fff",
              },
              emphasis: {
                show: false,
              },
            },
            itemStyle: {
              normal: {
                areaColor: "#0d0059",
                borderColor: "#389dff",
                borderWidth: 0.5,
              },
              emphasis: {
                areaColor: "#17008d",
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowBlur: 5,
                borderWidth: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
          {
            name: "",
            type: "effectScatter",
            coordinateSystem: "geo",
            data: dataValue,
          
            symbol: "circle",
            symbolSize: 8,
            hoverSymbolSize: 10,
            tooltip: {
              formatter(value) {
               return value.data.name + "<br/>" + value.data.num[0];
              },
              show: true,
            },
            encode: {
              value: 2,
            },
             showEffectOn: "render",
            rippleEffect: {
              brushType: "stroke",
            
              period: 9,
              scale: 5
            },
            hoverAnimation: true,
            label: {
              formatter: "{b}",
              position: "right",
              show: false
            },
            itemStyle: {
          
              shadowBlur: 2,
              shadowColor: "#333"
            },
            zlevel: 1
          },
        ],
      };

      this.myEchart.setOption(option);
    },
 getColor(v) {
                if (v < 60) {
                    return 'red';
                }
                if (v >= 80) {
                    return 'green';
                }
                return 'yellow';
            },
       jumpToRegion(item) {
         let query={...this.$store.state.query,region_id:item.id};
      jump("/battle/region",this, query);
            },
            jumpToArea(item) {
               let query={...this.$store.state.query,area_id:item.id};
      jump("/battle/area",this, query);
   
            },

  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/index.scss';
.container {
  background: url("../../assets/bg_battle.png");
  background-size: cover;
  background-position: bottom center;
  .content {
    .item {
      display: flex;
      flex-direction: column;
      margin-top: 0.185185rem /* 10/54 */;
      &.item_1 {
        width: 9.666667rem /* 522/54 */;
        height: 7.907407rem /* 427/54 */;
        background: url("../../assets/battle_map.png");
  position: relative;
        background-size: cover;
      }
      &.item_2 {
        width: 9.666667rem /* 522/54 */;
        height: 5.240741rem /* 283/54 */;
        background: url("../../assets/board.png");

        background-size: cover;
        .detail::after{
              content: '';
              display: block;
              width: 33.33%; 
        }
        .itemDetail{
          width: 33.33%;
         
        }
      }
      &.item_3 {
        flex-direction: row;
        justify-content: space-between;
        font-size: 0.277778rem /* 15/54 */;
        .subitem {
          width: 4.703704rem /* 254/54 */;
          height: 6.333333rem /* 342/54 */;
          background: url("../../assets/sub_item.png");
          background-size: cover;
          color: #fff;
          display: flex;
          flex-direction: column;

          .subtitle {
            margin-left: 0.388889rem /* 21/54 */;
            color: #00d5e9;
            font-size: 0.37037rem /* 20/54 */;
            height: 0.740741rem /* 40/54 */;
            line-height: 0.740741rem /* 40/54 */;
          }
          .subcontent {
            flex: 1;
            padding: 0.222222rem /* 12/54 */;
          }
          th,td{
            border:1px solid transparent
          }
        }
      }
      &.item_4 {
        width: 9.666667rem /* 522/54 */;
       border:1px solid #144cb9;
        box-shadow: 0px 0px 5px #144cb9 inset;
        color: #00d5e9;
        background-size: cover;
        font-size: 0.222222rem /* 12/54 */;
        padding: 0.185185rem /* 10/54 */;
        th {
          max-width: 0.851852rem /* 46/54 */;
        }
      }
      .table {
        width: 100%;
        color:#fff;
        text-align: center;
        tr {
          margin-bottom: 3px;
        }
        tr th {
          color: #00d5e9;
        }
        
        tr th,
        tr td {
          height: 0.592593rem /* 32/54 */;
          background: #010e4e;
          border-bottom: 0.055556rem /* 3/54 */ solid #000341;
        }
      }
      .title {
        margin-left: 0.481481rem /* 26/54 */;
        color: #00d5e9;
        font-size: 0.407407rem /* 22/54 */;
        height: 0.962963rem /* 52/54 */;

        line-height: 0.962963rem /* 52/54 */;
      }
      
    }
  }
}
.mapTips {
  position: absolute;

  z-index: 100000;
  bottom: 0.185185rem /* 10/54 */;
  left: 0.185185rem /* 10/54 */;
  color: #fff;
  // width: 2.055556rem /* 111/54 */;
  height: 1.851852rem /* 100/54 */;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0.148148rem /* 8/54 */ 0.259259rem /* 14/54 */;
  font-size: 0.222222rem /* 12/54 */;
  border-radius: 10px;
  border: 1px solid #0f6ebf;
}
.mapTips p span {
  margin-left: 0.092593rem /* 5/54 */;
  font-size: 0.222222rem; /* 12/54 */
}
.mapTips p:nth-child(1) span {
  color: green;
}
.mapTips p:nth-child(2) span {
  color: yellow;
}
.mapTips p:nth-child(3) span {
  color: red;
}
</style>